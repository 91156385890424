<template>
    <section class="select-vehicle-area">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div v-for="(car, index) in carTypes"
                        :key="index" 
                        :class="['select-car', {s1: ((index % 2) > 0)}]">
                        <div class="image-car one-half">
                            <!-- <img :src="car.carImage" alt=""> -->
                            <VueSlickCarousel v-bind="settings">
                                <img v-for="(img, index) in car.carImage"
                                    :key="index"
                                    :src="img.imageUrl" alt="">
                            </VueSlickCarousel>
                        </div>
                        <div class="box-text one-half">
                            <div class="top">
                                <h3>{{car.carType}} - {{car.categoryId.categoryName}}</h3>
                                <div class="name-car">{{car.feature}}</div>
                            </div>
                            <div class="content">
                                <p>{{car.smallDescription}}</p>
                                <ul>
                                    <li><img src="/images/booking/people.png" alt=""> Max . {{car.categoryId.maxPersons}}</li>
                                    <li><img src="/images/booking/vali.png" alt=""> Max . {{car.categoryId.maxBags}}</li>
                                </ul>
                            </div>
                            <div class="bottom">
                                <div class="price" v-if="env.VUE_APP_ONEWAY_TRIP === tripType">
                                    <span>${{car.estimatedCost}}</span>                                   
                                </div>
                                <div class="price" v-if="env.VUE_APP_HOUR_TRIP === tripType">
                                    <span>${{car.pricePerUnitHour * booking.location.selectedHour}}</span>
                                </div>
                                <div class="btn-select">
                                    <a href="javascript:void(0)" @click="selectCar(car)"><span>SELECT</span></a>
                                </div>
                                <p v-if="matchCarForDiscount(car.carType, bigVehicle) && env.VUE_APP_HOUR_TRIP === tripType && booking.location.selectedHour <= 3" class="discountBanner">
                                    Minimum hours for {{car.carType}} is 4 but you selected {{ booking.location.selectedHour }} hours, If you want to choose {{car.carType}}, hours will update to 4.
                                </p>
                                <p v-if="matchCarForDiscount(car.carType, smallVehicle) && env.VUE_APP_HOUR_TRIP === tripType && booking.location.selectedHour <= 2" class="discountBanner">
                                    Minimum hours for {{car.carType}} is 3 but you selected {{ booking.location.selectedHour }} hours, If you want to choose {{car.carType}}, hours will update to 3.
                                </p>
                            </div>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { mapGetters } from 'vuex';
import { request } from "../../utils/api";
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
    components: { VueSlickCarousel },
    data(){
        return{
            carTypes: [],
            tripType: '',
            env: process.env,
            bigVehicle: ["limo", "mercedes", "executive", "minibus"],
            smallVehicle: ["sedan", "suv", "first class"],
            settings: {
				"autoplay": true,
				"edgeFriction": 0.35,
				"fade": true,
  				"infinite": true,
				"speed": 500,
				"slidesToShow": 1,
				"cssEase": "linear",
				"slidesToScroll": 1
			}
        }
    },
    computed:{
        ...mapGetters(['booking'])
    },
    methods:{
        matchCarForDiscount(string, conditions){
            return conditions.some(el => string.toLowerCase().includes(el));
        },
        async getCarTypes(){
            const resp = await request({
                method: 'get',
                url: `/api/v1/user/cartypes`,
            })
            if (resp.remote === "success") {
                this.carTypes = resp.data;
            }else{
                
            }
        },
        selectCar(item){
            if(this.matchCarForDiscount(item.carType, this.bigVehicle) && this.env.VUE_APP_HOUR_TRIP === this.tripType && this.booking.location.selectedHour <= 3){
                this.$store.commit('updateHours', 4);
            }
            if(this.matchCarForDiscount(item.carType, this.smallVehicle) && this.env.VUE_APP_HOUR_TRIP === this.tripType && this.booking.location.selectedHour <= 2){
                this.$store.commit('updateHours', 3);
            }
            this.$store.commit('setSelectedCarType', item);
            this.$router.push({name: 'bookingOptions'});
        },
        async estimateCost() {
            const jsonDataPass = {
                source: {
                    startLocationAddr: this.booking.location.pickUpLocation.formattedAddress,
                    startLocation: {
                        lat: this.booking.location.pickUpLocation.lat,
                        lng: this.booking.location.pickUpLocation.lng,
                    },
                    addressComponents: {},
                },
                destination: {
                    endLocationAddr: this.booking.location.dropOffLocation.formattedAddress,
                    endLocation: {
                        lat: this.booking.location.dropOffLocation.lat,
                        lng: this.booking.location.dropOffLocation.lng,
                    },
                    addressComponents: {},
                },
                isReturnWay: this.booking.location.isReturnWay
            };

            if (Object.keys(this.booking.location.stopLocation).length) {
                jsonDataPass.stop = {
                stopLocationAddr: this.booking.location.stopLocation.formattedAddress,
                stopLocation: {
                    lat: this.booking.location.stopLocation.lat,
                    lng: this.booking.location.stopLocation.lng,
                },
                addressComponents: {},
                };
            }

            const resp = await request({
                method: "POST",
                url: "/api/v1/trip/estimatedcostall",
                data: jsonDataPass,
            });
            console.log(resp)
            if (resp.remote == "success") {
                this.carTypes = resp.data.map((e) => {
                    return { estimatedCost: e.estimatedCost, isRideMinimum: e.isRideMinimum, minimumDistance: e.minimumDistance, ...e.carTypeResponse}
                })

                console.log(this.carTypes)
                // this.estimatedCostData = resp.data;
                // this.$store.commit("setEstimatesDetails", this.estimatedCostData);
                // this.addressPreFilled = true;
            }
            },
    },
    mounted(){
        if(!this.booking.location){
            this.$router.push({name: 'home'})
            return false;
        }

        this.tripType = this.booking.location.type;
        
        if (this.booking.location) {
            if (this.booking.location.type == process.env.VUE_APP_HOUR_TRIP) {
                this.getCarTypes();
            } else {
                this.estimateCost();
                
            }
        }
    }
}
</script>

<style scoped>
.discountBanner{
      background: yellow;
    color: red;    
    font-weight: 700;
    font-size: 13px;
    text-align: center;
    padding: 0px 5px;
    line-height: 25px;
    margin: 5px 0;
}
</style>